import { useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";

import { GET_ADMIN_ZONE_ADDRESS_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminZoneAddress";
import { ZONE_ID_OF_FULFILLMENT_CENTER_INCHEON_1 } from "@sellernote/_shared/src/constants/forwarding/common";
import { BidTransportType } from "@sellernote/_shared/src/types/forwarding/bid";
import AutoCompleteWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/AutoCompleteWithReactHookForm";
import RadioGroupWithReactHookForm, {
  RadioGroupWithReactHookFormOption,
} from "@sellernote/_shared-for-forwarding-admin/src/components/RadioGroupWithReactHookForm";
import TextFieldWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/TextFieldWithReactHookForm";

import { inlandTypeRadioGroupOptions } from ".";

function Import({
  endAddress,
  importCarrier,
  zoneNameListOptions,
  zoneAddress,
  getPortNameListOptions,
  startTypeRadioGroupOption,
  endTypeRadioGroupOption,
}: {
  endAddress: string | undefined;
  importCarrier: "shipda" | "customer";
  zoneNameListOptions: string[];
  zoneAddress: GET_ADMIN_ZONE_ADDRESS_RES | undefined;
  getPortNameListOptions: (type: "startType" | "endType") => string[];
  startTypeRadioGroupOption: RadioGroupWithReactHookFormOption<BidTransportType>[];
  endTypeRadioGroupOption: RadioGroupWithReactHookFormOption<BidTransportType>[];
}) {
  const { control, watch, setValue } = useFormContext();

  const [isBofulAddress, setIsBofulAddress] = useState(false);
  const [prevEndAddress, setPrevEndAddress] = useState(endAddress);
  const [prevImportCarrier, setPrevImportCarrier] = useState(importCarrier);

  const startType = watch("startType");

  const endType = watch("endType");

  const zoneId = watch("zoneId");

  const TransportSectionRef = useCallback(
    (node) => {
      if (node !== null) {
        /**
         * endAddress를 입력 시 zoneId를 등록해주는 로직
         * endAddress과 기존 주소(prevEndAddress)와 다르거나 zoneId가 없을때(첫 주소 등록 시)
         */
        if (endAddress !== prevEndAddress || !zoneId) {
          setPrevEndAddress(endAddress);
          if (zoneAddress && zoneAddress.length > 0) {
            setValue(
              "zoneId",
              zoneAddress?.find((v) => {
                return (
                  v.roadAddress === endAddress || v.jibunAddress === endAddress
                );
              })?.id
            );
          }

          /** 견적확정에서 돌아왔을 때 보풀 주소가 있으면 보풀체크를 true로 변경 */
          if (
            !prevEndAddress &&
            zoneId === ZONE_ID_OF_FULFILLMENT_CENTER_INCHEON_1
          ) {
            setIsBofulAddress(true);
          }
        }

        if (importCarrier !== prevImportCarrier) {
          setPrevImportCarrier(importCarrier);
          if (importCarrier === "customer") {
            setValue("endType", "inland");
            return;
          }

          /** 견적확정에서 돌아 왔을 때 내륙이 있다면 내륙으로 변경해준다. */
          if (
            !prevImportCarrier &&
            importCarrier === "shipda" &&
            endType === "inland"
          ) {
            setValue("endType", "inland");
            return;
          }

          setValue("endType", "sea");
          return;
        }
      }
    },
    [
      endAddress,
      endType,
      importCarrier,
      zoneAddress,
      prevEndAddress,
      prevImportCarrier,
      setValue,
      zoneId,
    ]
  );

  const handleBofulAddressCheck = () => {
    if (isBofulAddress) {
      setIsBofulAddress(false);
      setValue("endAddress", null);
      setValue("endAddressDetail", null);
      setValue("zoneId", null);
      return;
    }
    setIsBofulAddress(true);
    setValue("endAddress", "인천광역시 중구 자유무역로 232");
    setValue("endAddressDetail", "3층 쉽다 풀필먼트 센터");
    setValue("zoneId", ZONE_ID_OF_FULFILLMENT_CENTER_INCHEON_1);
    return;
  };

  return (
    <Grid
      ref={TransportSectionRef}
      item
      container
      spacing={3}
      alignItems="base-line"
      xs={12}
    >
      <Grid item container spacing={3}>
        <Grid item xs={1}>
          <Typography variant="subtitle1" component="span">
            출발지
          </Typography>
        </Grid>

        <Grid item container xs={5} direction="column">
          <Grid item>
            <RadioGroupWithReactHookForm
              name="startType"
              control={control}
              defaultValue="sea"
              radioGroupOption={startTypeRadioGroupOption}
              handleEffectOnChange={(e) => {
                // 출발지 타입을 변경하는 경우 입력한 출발지 정보를 제거
                if (e.target.value === "sea" || e.target.value === "air") {
                  setValue("startAddress", null);
                  setValue("startViaPort", null);

                  return;
                }

                // 반대인 경우 출발지 항구를 제거
                setValue("startPort", null);
              }}
            />
          </Grid>

          <Grid item>
            <TextFieldWithReactHookForm
              name={"startAddress"}
              control={control}
              placeholder="출발지 주소"
              disabled={startType !== "inland"}
            />
          </Grid>

          {startType !== "inland" && (
            <Grid item>
              <AutoCompleteWithReactHookForm
                name={"startPort"}
                control={control}
                label="항구/공항 선택"
                // portList에서 같은 이름으로 중복된 항구/공항을 제거
                options={getPortNameListOptions("startType")}
              />
            </Grid>
          )}

          {startType === "inland" && importCarrier !== "customer" && (
            <Grid item>
              <AutoCompleteWithReactHookForm
                name={"startViaPort"}
                control={control}
                label="항구/공항 선택"
                // portList에서 같은 이름으로 중복된 항구/공항을 제거
                options={getPortNameListOptions("startType")}
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item container spacing={3}>
        <Grid item xs={1}>
          <Typography variant="subtitle1" component="span">
            도착지
          </Typography>
        </Grid>

        <Grid item container xs={5} direction="column" spacing={1}>
          <Grid item>
            <RadioGroupWithReactHookForm
              name="endType"
              control={control}
              defaultValue="sea"
              radioGroupOption={endTypeRadioGroupOption}
              handleEffectOnChange={(e) => {
                // 도착지 타입을 변경하는 경우 입력한 도착지 정보를 제거
                if (e.target.value === "sea" || e.target.value === "air") {
                  setValue("endAddress", null);
                  setValue("endAddressDetail", null);
                  setValue("zoneId", null);
                  setValue("endViaPort", null);
                  setIsBofulAddress(false);

                  return;
                }
                // 반대인 경우 도착지 항구를 제거
                setValue("endPort", null);
              }}
            />
          </Grid>

          {endType !== "inland" && importCarrier !== "customer" && (
            <Grid item>
              <AutoCompleteWithReactHookForm
                name={"endPort"}
                control={control}
                label="항구/공항 선택"
                // portList에서 같은 이름으로 중복된 항구/공항을 제거
                options={getPortNameListOptions("endType")}
              />
            </Grid>
          )}

          {endType === "inland" && (
            <Grid item>
              <AutoCompleteWithReactHookForm
                name={"endViaPort"}
                control={control}
                label="항구/공항 선택"
                // portList에서 같은 이름으로 중복된 항구/공항을 제거
                options={getPortNameListOptions("endType")}
              />
            </Grid>
          )}

          <Grid item container>
            <Grid item xs={8}>
              <AutoCompleteWithReactHookForm
                name={"endAddress"}
                control={control}
                label="도착지 주소"
                options={zoneNameListOptions}
                disabled={endType !== "inland"}
              />
            </Grid>

            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={endType !== "inland"}
                    checked={isBofulAddress}
                    onClick={handleBofulAddressCheck}
                  />
                }
                label="보풀 이용"
              />
            </Grid>
          </Grid>
          <Grid item>
            <TextFieldWithReactHookForm
              name={"endAddressDetail"}
              control={control}
              placeholder="상세주소"
              disabled={endType !== "inland"}
            />
          </Grid>

          {endType === "inland" && (
            <Grid item>
              <RadioGroupWithReactHookForm
                name="inlandType"
                control={control}
                defaultValue="sole"
                disabled={watch("freightType") === "FCL"}
                radioGroupOption={inlandTypeRadioGroupOptions}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Import;
