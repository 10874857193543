import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material";

import { BID_COPY_TARGET_FOR_CHECKBOX } from "@sellernote/_shared/src/constants/forwarding/adminBid";
import {
  BidCopyStatus,
  BidCopyTarget,
} from "@sellernote/_shared/src/types/forwarding/adminBid";

export default function useBidCopyTargetCheckbox({
  copyStatusOfRadio,
  isImport,
}: {
  copyStatusOfRadio: BidCopyStatus;
  isImport: boolean;
}) {
  const defaultBidCopyTargetValue: BidCopyTarget = useMemo(() => {
    let checkBoxValues = {
      defaultInfo: true,
      copyEstimatePartner: false,
      contactEstimatePartner: false,
      copyEstimate: false,
      submitEstimate: false,
      acceptEstimate: false,
      copyExporterInfo: false,
      copyBookingPartner: false,
      contactBookingPartner: false,
    };

    if (copyStatusOfRadio === "waiting") {
      checkBoxValues = {
        ...checkBoxValues,
        copyEstimate: true,
        submitEstimate: true,
      };
    }

    if (copyStatusOfRadio === "waitingForExporterInfo") {
      checkBoxValues = {
        ...checkBoxValues,
        copyEstimate: true,
        submitEstimate: true,
        acceptEstimate: true,
      };
    }

    // 수출일때 수출자 컨택 전, 중 대신 사용하는 화물 준비 중 상태
    if (copyStatusOfRadio === "waitingFreight") {
      checkBoxValues = {
        ...checkBoxValues,
        copyEstimate: true,
        submitEstimate: true,
        acceptEstimate: true,
      };
    }

    if (copyStatusOfRadio === "beforeContactPartner") {
      checkBoxValues = {
        ...checkBoxValues,
        copyEstimate: true,
        submitEstimate: true,
        acceptEstimate: true,
        copyExporterInfo: true,
      };
    }

    if (copyStatusOfRadio === "contactingPartner") {
      checkBoxValues = {
        ...checkBoxValues,
        copyEstimate: true,
        submitEstimate: true,
        acceptEstimate: true,
        copyExporterInfo: true,
        copyBookingPartner: true,
        contactBookingPartner: true,
      };
    }

    return checkBoxValues;
  }, [copyStatusOfRadio]);

  const [bidCopyTargetValue, setBidCopyTargetValue] = useState(
    defaultBidCopyTargetValue
  );

  useEffect(() => {
    setBidCopyTargetValue(defaultBidCopyTargetValue);
  }, [defaultBidCopyTargetValue]);

  const getDisabledValueOfCheckbox = useCallback(
    (checkboxName: keyof BidCopyTarget) => {
      /** 수입운송의 경우, 부킹 파트너 컨택 없이 의뢰복사 가능 (#13817) */
      if (
        isImport &&
        checkboxName === "contactBookingPartner" &&
        copyStatusOfRadio === "contactingPartner"
      ) {
        return false;
      }

      if (checkboxName === "copyEstimatePartner") {
        if (copyStatusOfRadio === "committed") {
          return true;
        }
        return false;
      }

      if (checkboxName === "contactEstimatePartner") {
        // TODO: 수출에서 견적파트너 컨택을 사용할 수 없음 백엔드 요청으로 임시로 비활성화
        if (copyStatusOfRadio === "estimating" && isImport) {
          return false;
        }
        return true;
      }

      if (!isImport) {
        if (
          checkboxName === "copyBookingPartner" ||
          checkboxName === "copyExporterInfo" ||
          checkboxName === "contactBookingPartner"
        ) {
          if (copyStatusOfRadio === "waitingFreight") {
            return false;
          }
          return true;
        }
      }

      if (checkboxName === "copyBookingPartner") {
        if (copyStatusOfRadio === "beforeContactPartner") {
          return false;
        }
        return true;
      }

      return true;
    },
    [copyStatusOfRadio, isImport]
  );

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      return setBidCopyTargetValue({
        ...bidCopyTargetValue,
        [event.target.name]: event.target.checked,
      });
    },
    [bidCopyTargetValue]
  );

  const BidCopyTargetCheckbox = useMemo(() => {
    return (
      <FormControl>
        <FormLabel>복사 대상</FormLabel>

        <FormGroup>
          {BID_COPY_TARGET_FOR_CHECKBOX.map(({ value, label }) => (
            <FormControlLabel
              key={value}
              control={
                <Checkbox
                  checked={bidCopyTargetValue[value]}
                  name={value}
                  onChange={handleCheckboxChange}
                  disabled={getDisabledValueOfCheckbox(value)}
                  size="small"
                />
              }
              label={label}
            />
          ))}
        </FormGroup>
      </FormControl>
    );
  }, [bidCopyTargetValue, getDisabledValueOfCheckbox, handleCheckboxChange]);

  return { BidCopyTargetCheckbox, bidCopyTargetValue };
}
